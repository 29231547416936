
import Vue from 'vue';

import { mapActions, mapState } from 'vuex';

import { usersActions } from '@/store/modules/users/names';

import createFormUser from './UserCreate.vue';
import { ComposedState } from '@/types';

interface ISort {
    status?: boolean;
    // eslint-disable-next-line camelcase
    is_active?: boolean;
};

interface ISortOptions {
    itemsPerPage: number;
    page: number;
    sortBy: string[];
    sortDesc: boolean[];
    search: string;
};

export default Vue.extend({
    name: 'Users',

    components: {
        createFormUser,
    },

    data() {
        return {
            search: '',
            options: {} as ISortOptions,
            loading: true,
            footerProps: {
                'items-per-page-options': [2, 5, 10, 15],
            },
            headers: [
                {
                    text: 'Name',
                    align: 'left',
                    sortable: false,
                    value: 'name',
                },
                { text: 'Id', value: 'id' },
                { text: 'Created at', value: 'created_at' },
                { text: 'Email', value: 'verified_email' },
                { text: 'Status', value: 'is_active' },
            ],
        };
    },

    computed: {
        ...mapState<ComposedState>({
            users: (state: ComposedState) => state.users.users,
            count: (state: ComposedState) => state.users.count,
        }),
    },

    watch: {
        search: {
            handler() {
                this.options.page = 1;
                this.querySortUser().then(() => {
                    this.loading = false;
                });
            },
            deep: true,
        },
        options: {
            handler() {
                this.querySortUser().then(() => {
                    this.loading = false;
                });
            },
            deep: true,
        },
    },

    methods: {
        ...mapActions({
            fetchUsers: usersActions.fetchUsers,
        }),

        async querySortUser() {
            this.loading = true;
            const { page, itemsPerPage, sortBy, sortDesc } = this.options;
            
            const sorting: ISort = {};

            if (sortBy.length) {
                sortBy.forEach((item, index) => {
                    sorting[item] = sortDesc[index] ? 'desc' : 'asc';
                });

                if (sorting.hasOwnProperty('is_active')) {
                    sorting.status = sorting.is_active;
                    delete sorting.is_active;
                }
            }

            const query = {
                search_query: '',
                size: itemsPerPage,
                page,
                sort_by: Object.keys(sorting)[0],
                sort: Object.values(sorting)[0],
            };

            if (this.search) {
                query.search_query = this.search;
            }

            await this.fetchUsers(query);
        },
    },
});
